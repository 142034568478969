@tailwind base;
@tailwind components;
@tailwind utilities;

@import url('https://fonts.googleapis.com/css2?family=Outfit:wght@100..900&display=swap');

* {
  font-family: "Outfit", sans-serif;
  font-optical-sizing: auto;
  font-style: normal;
}

body {
  min-height: 100vh;
  @apply bg-gray-100;
}

h4 {
  @apply text-2xl font-bold !important;
}

a {
  @apply hover:underline;
}

#root {
  min-height: 100vh;
  @apply bg-gray-100;
}

#root #template {
  display: flex;
  min-height: 100Vh;
}

#root #aside {
  flex-shrink: 0;
  width: 280px;
  min-height: 100vh;
}

#root #main {
  -webkit-box-flex: 1;
  flex-grow: 1;
  min-height: 100%;
  @apply bg-gray-100;
}


#root #aside .MuiFormControl-root .MuiFormLabel-root {
  @apply text-gray-200;
}


#root #aside .MuiFormControl-root .MuiInputBase-root {
  @apply text-gray-200;
}

#root #aside .MuiFormControl-root .MuiSvgIcon-root {
  @apply text-gray-200;
}


.margin {
  @apply mx-auto px-4 md:px-8;
}

.content {
  @apply  mx-auto my-10 px-4 md:px-8;
}




/* DataGrid */
.MuiTableHead-root {
  @apply bg-gray-100;
}

/* End DataGrid */



/* MuiBreadcrumbs */
.MuiBreadcrumbs-root .MuiBreadcrumbs-li a {
  @apply hover:underline text-dark-600 text-sm;
}

.MuiBreadcrumbs-root .MuiBreadcrumbs-li p {
  @apply text-dark-100 text-sm;
}

/* End MuiBreadcrumbs */


.MuiAccordion-root {
  @apply m-0 p-0 !important;
}

.MuiAccordion-root.shadow-none {
  @apply rounded-[3px];
  box-shadow: none;
}


.MuiPaper-root {
  @apply border border-gray-200;
}


.queryBuilder {
  @apply grid gap-4;
}

.queryBuilder .ruleGroup {
  @apply !bg-transparent grid gap-3 !border-none p-0 ;
}

.queryBuilder .ruleGroup:not(:first-child) {
  @apply  !bg-gray-300;
}

.queryBuilder .ruleGroup-header {
  @apply flex !gap-4;
}


.queryBuilder .ruleGroup-header button[title="Add rule"],
.queryBuilder .ruleGroup-header button[title="Add group"] {
  @apply !bg-green-500 !text-white border-none shadow-md;
}

.queryBuilder .ruleGroup-header button[title="Remove group"] {
  @apply !bg-red-500 !text-white border-none shadow-md;
}


.queryBuilder .ruleGroup-body {
  @apply grid !gap-2;
}


.queryBuilder .ruleGroup-body .rule {
  @apply !flex !gap-4;
}

.queryBuilder .ruleGroup-body .rule button[title="Remove rule"] {
  @apply !bg-red-500 !text-white;
}

.queryBuilder .ruleGroup-body .rule div {
  @apply !flex-1;
}

.queryBuilder button {
  @apply !bg-gray-700;
}

.queryBuilder .ruleGroup-body .ruleGroup {
  @apply bg-dark-100 p-4;
}

.svg-font-color svg>path {
  fill: var(--ifm-font-color-base);
}

.queryBuilder {
  min-width: 420px;
}

.validateQuery.queryBuilder .ruleGroup.queryBuilder-invalid {
  @apply !bg-green-500;
}

.validateQuery.queryBuilder .ruleGroup.queryBuilder-invalid .ruleGroup-addRule {
  font-weight: bold !important;
}

.validateQuery.queryBuilder .ruleGroup.queryBuilder-invalid>.ruleGroup-header::after {
  content: "Empty groups are considered invalid. Avoid them by using addRuleToNewGroups.";
  color: white;
}

.validateQuery.queryBuilder .rule.queryBuilder-invalid .rule-value {
  @apply !bg-green-500;
}

.validateQuery.queryBuilder .rule.queryBuilder-invalid .rule-value::placeholder {
  @apply !text-green-500;
}

.justifiedLayout.queryBuilder .ruleGroup-addGroup+button.ruleGroup-cloneGroup,
.justifiedLayout.queryBuilder .ruleGroup-addGroup+button.ruleGroup-lock,
.justifiedLayout.queryBuilder .ruleGroup-addGroup+button.ruleGroup-remove {
  margin-left: auto !important;
}

.justifiedLayout.queryBuilder .rule-operators+button.rule-cloneRule,
.justifiedLayout.queryBuilder .rule-operators+button.rule-lock,
.justifiedLayout.queryBuilder .rule-operators+button.rule-remove,
.justifiedLayout.queryBuilder .rule-value+button.rule-cloneRule,
.justifiedLayout.queryBuilder .rule-value+button.rule-lock,
.justifiedLayout.queryBuilder .rule-value+button.rule-remove,
.justifiedLayout.queryBuilder .control+button.rule-cloneRule,
.justifiedLayout.queryBuilder .control+button.rule-lock,
.justifiedLayout.queryBuilder .control+button.rule-remove,
.justifiedLayout.queryBuilder .chakra-select__wrapper+button.rule-cloneRule,
.justifiedLayout.queryBuilder .chakra-select__wrapper+button.rule-lock,
.justifiedLayout.queryBuilder .chakra-select__wrapper+button.rule-remove {
  margin-left: auto !important;
}



.MuiAutocomplete-popper {
  width: auto !important;
}